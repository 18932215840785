<template>
  <div id="app">
    <!-- 报废设备 -->
    <el-row>
      <el-col :span="6" class="rows">
        <el-select v-model="deviceType" placeholder="请选择设备类型" clearable>
          <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.name" :value="item.name" @click.native="selectOptions(item)">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="rows" style="margin-left:-370px">
        <el-button type="primary" style="margin-left:5px" @click="queryInfo">查询</el-button>
      </el-col>
      <el-col :span="6" class="rows" style="float:right;width:10%;margin:10px 0">
        <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
          <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-table :stripe="true" :data="scrapDeviceList">
      <el-table-column v-if="tableTitle.includes('设备编号')" label="设备编号" prop="id" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('设备类型')" label="设备类型" prop="typeId" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('单位')" label="单位" prop="ciId" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('安装位置')" label="安装位置" prop="location" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('报废时间')" label="报废时间" prop="overhaulTime" align="center"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <div>
      <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange" class="pagination"></el-pagination>
    </div>

  </div>
</template>

<script>

import deviceInfo from '@/api/facilitiesManagement/DeviceInfo'
import companySystem from "@/api/managementApi/CompanyInfo";

export default {
  name: "AbnormalDevice",
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '设备编号',
      }, {
        id: 2,
        title: '设备类型',
      }, {
        id: 3,
        title: '单位',
      }, {
        id: 4,
        title: '安装位置',
      }, {
        id: 6,
        title: '报废时间',
      }],
      tableTitle: ['设备编号', '设备类型', '单位', '安装位置', '报废时间'],

      deviceType: '',
      deviceTypeId: '',
      // 设备类型
      deviceTypeList: [],

      // 页码
      limit: 10,
      current: 1,
      total: 1,

      // 所有公司
      companyInfoList: [],

      // 报废设备
      scrapDeviceList: [],
    };
  },
  created() {
    // 查所有公司
    this.getCompanyInfo()

    //   查报废设备
    this.getScrapDevicecInfo();

    // 设备类型
    this.getDeviceManagementType()

  },


  methods: {
    // 分页
    handleCurrentChange(val) {
      this.getScrapDevicecInfo(this.current, this.limit);
    },
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('设备编号')
      } else {
        this.tableTitle = value
      }
    },
    // 所有公司
    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        this.companyInfoList = res.data.data.list;
      });
    },

    // 查询报废设备信息
    getScrapDevicecInfo() {
      deviceInfo.getAllScrapInfo(this.current, this.limit, this.deviceType).then(res => {
        this.scrapDeviceList = res.data.data.list
        this.total = res.data.data.total;
        if (res.data.message == "无异常设备") {
          this.scrapDeviceList = []
        }
        // else {
        //   for (let i = 0; i < this.scrapDeviceList.length; i++) {
        //     for (let j = 0; j < this.companyInfoList.length; j++) {
        //       if (this.scrapDeviceList[i].ciId == this.companyInfoList[j].id) {
        //         this.scrapDeviceList[i].ciId = this.companyInfoList[j].name;
        //       }
        //     }
        //   }
        // }
      })
    },

    // 设备类型
    getDeviceManagementType() {
      deviceInfo.getDeviceManagementType().then(res => {
        this.deviceTypeList = res.data.data.list;
      })
    },

    selectOptions(item) {
      this.deviceType = item.name;
      this.deviceTypeId = item.id;
      console.log(this.deviceTypeId);
    },

    // 查询设备
    queryInfo() {
      this.getScrapDevicecInfo(this.current, this.limit, this.deviceType);
    },



  },
};
</script>

<style lang="scss" scoped>
.rows {
  margin: 10px 10px 10px 0;
}
</style>